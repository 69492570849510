import React from 'react'
import NavBar from '../layouts/Navbar'
import {Input,Button} from "@nextui-org/react"
import { getPostcodeFromCoords } from '../utils/Postcodes/GetPostcodeFromCoords'

import { useNavigate } from 'react-router-dom'
import Footer from '../layouts/Footer'

export default function HomePage() {
    const postCodeRef = React.useRef(null)
    const [postcode, setPostcode] = React.useState(null)

    const navigate = useNavigate()

    function handleSearch() {
        console.log(postCodeRef.current.value)
        navigate("/search?postcode="+(postCodeRef.current.value).toUpperCase()+"&distance=300")
        // /search?location=&distance=300
    }

    function useCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                getPostcodeFromCoords(position.coords.latitude,position.coords.longitude).then(p => setPostcode(p))
                
                // return getPostcodeFromCoords(position.coords.latitude,position.coords.longitude)
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }

    }
    // window.navigator.geolocation.getCurrentPosition(console.log, console.log);
    // 

  return (
    <>
    <div className="h-screen flex flex-col justify-between w-screen p-0 m-0">
    <NavBar />
    <div className="w-screen flex justify-center items-center text-center flex flex-col">
        
        <div className="mt-32 mb-32">
            <h1 className="text-caresaverblue text-7xl md:text-8xl my-1 font-bold">myMedi</h1>
            <h3 className="text-caresaverblue text-xl md:text-xl my-1">Save money on your private healthcare</h3>
        
                <div className="flex-row flex h-12 justify-center px-4 text-blue-400 mt-10 h">
                    <Input ref={postCodeRef} className="flex w-48 text-black" type="text" variant="bordered" label="Postcode" placeholder="" value={postcode}/>
                    <Input ref={postCodeRef} className="flex md:w-96 text-black" type="text" variant="bordered" label="I'm looking for..." placeholder="Optional"/>

                    <Button color="" size="lg" className="ml-2 bg-caresaverblue text-white" onClick={handleSearch}>
                        Search
                    </Button>
                </div>
                <button hidden onClick={useCurrentLocation} className="underline text-caresaverblue mt-2">Use my current location</button>
        </div>
    </div>
    <Footer />
    </div>
    </>
  )
}
// <div className="bottom-0 prose lg:prose-xl w-screen bg-caresaverblue">
//<div className="flex flex-row">
//<p className="text-gray-400"><a>About</a></p>
//</div>
//</div>
/*
<div className="container flex flex-col justify-center items-center text-center">
       <div class="prose mx-auto">
        
        </div>
        </div>
*/