import { Card, CardBody,Button } from '@nextui-org/react'
import React from 'react'

export default function PracticeCard({name,price,location,postcode,address,treatments}) {

  const [isOpen, setIsOpen] = React.useState(false)

  // This is a temporary function to find the lowest treatment price of a practice
  // This should be changed later to use the search term to find the lowest price of that thing searched for
  function findLowestPrice() {
    let lowestPrice = 1000000
    
    if (treatments === undefined) {
      return false
    }

    treatments.forEach((treatment) => {
      if (treatment.price < lowestPrice) {
        lowestPrice = treatment.price
      }
    })
    return lowestPrice
  }


  return (
    <>
    <Card className="mx-auto my-2 prose bg-background/60 py-0 px-0 max-w-screen-md w-96">
        <CardBody className="pb-3 pt-0 flex flex-col justify-between items-center">
          <h3 className="my-0">Consultations From</h3>
          <div className="flex justify-around w-full">
            <div className="flex-col flex">
              <p className="my-0 py-0 text-l text-center">Initial</p>
              <p className="text-3xl my-0 py-0 text-center">£{findLowestPrice()}</p>
            </div>
            <div>
              <p className="my-0 py-0 text-l text-center">Follow-up</p>
              <p className="text-3xl my-0 py-0 text-center">£100</p>
            </div>
          </div>

          <div className="mt-1">
            <a className="text-caresaverblue text-2xl no-underline">{name}</a>
          </div>
  
          <div className="mt-0 pt-0 mb-2">
            <p className="my-0 py-0 text-center">{address}</p>
          </div>

          <div>
            <Button onClick={()=>setIsOpen(!isOpen)} className="bg-caresaverblue text-white">View Specialities</Button>
          </div>
          <ul hidden={!isOpen}>
            <li>John Smith - Ear, Nose and Throat</li>
            <li></li>
          </ul>
        </CardBody>
    </Card>
    </>
  )
}

//<div className="w-fit ">
//<h3 className="text-primary fs-4 mt-2"><a className="text-inherit no-underline">{name}</a></h3>
//<p className="mb-2">{address}</p>
//</div>

//<div className="w-max">
//<p>From: <span className="text-xl">{findLowestPrice()}</span></p>
//</div>