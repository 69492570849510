import React from 'react'

export default function Footer() {
  return (
    <footer className="footer w-screen bg-caresaverblue text-white flex flex-row px-4 justify-between">
        <div className="py-2 flex flex-row">
            
            <p className="mx-2">About</p>
            <p className="mx-2">Contact</p>
        </div>
        <div className="py-2 flex-row flex">
            <p className="mx-2">Advertising</p>
            <p className="mx-2">Terms</p>
            <p className="mx-2">Privacy</p>
        </div>
    </footer>
  )
}
