import React, { useRef, useState,useEffect} from 'react'
import Footer from '../../layouts/Footer'
import MenuBar from '../../layouts/Navbar'
import { useAuth } from '../../context/AuthContext'
import { Input } from '@nextui-org/react'
import { Button } from '@nextui-org/react'

import { useNavigate, Link} from 'react-router-dom'

export default function Login() {
    // Send a page view event to Firebase Analytics
 
    const emailRef = useRef()
    const passwordRef = useRef()
    const [error,setError] = useState('')
    const [loading,setLoading] = useState(false)
    const { login,currentUser }  = useAuth()
    const navigate = useNavigate()

    const handleSubmit = async(e)=> {
        e.preventDefault()
        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value,passwordRef.current.value)
            navigate('/profile')
        } catch (e) {
            console.log(e)
            switch (e.code) {
              case 'auth/user-not-found':
                setError('User not found')
                break;
              case 'auth/wrong-password':
                setError('Incorrect password')
                break;
              case 'auth/too-many-requests':
                setError('Too many requests. Try again later.')
                break;
              default:
                setError('Could not login')
              break;

        }
        }
        setLoading(false)
        }

  return (
    <>
    <div className="h-screen flex flex-col justify-between w-screen p-0 m-0">
    
    <MenuBar />
    <div class="mx-auto text-center my-5">
      <form class="p-5 shadow" onSubmit={handleSubmit}>
       
        <h1 class="text-xl my-4">Welcome back!</h1>
        {error && <p className="alert alert-danger alert-dismissible">{error}</p>}
       
          <Input type="email" className="my-2" id="floatingInput" label="Email Address" ref={emailRef} required/>
      
          <Input type="password" className="my-2" id="floatingPassword" label="Password" ref={passwordRef} required/>
  
        <Button className="w-full bg-caresaverblue" type="submit" disabled={loading}>Login</Button>
            
        <div class="mt-2">
          <Link className="text-caresaverblue underline" to="/reset-password">Forgot password?</Link>
          <p>Dont have an account? <Link className="text-caresaverblue underline" to="/signup">Sign up here!</Link></p>
        </div>
      </form>
    </div>
    <Footer />
    </div>
  </>
  )
}