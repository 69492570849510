export async function getCoordsFromPostcode(postCode) {
    try {
        // Get the coordinates of a post code from postcodes.io api
        const response = await fetch(`https://api.postcodes.io/postcodes/${postCode}`);
        const data = await response.json();

        if (data.status !== 200) {
            throw new Error('Post code not found');
        }
        // Get the coordinates of the post code
        const { longitude, latitude } = data.result;

        return [longitude, latitude];
    }
    catch (error) {
        throw error;
    }
}
