import React, { useRef, useState,useEffect} from 'react'
import Footer from '../../layouts/Footer'
import MenuBar from '../../layouts/Navbar'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Input,Button } from '@nextui-org/react'




export default function Signup() {
    // Send a page view event to Firebase Analytics

  
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const [error,setError] = useState('')
    const [loading,setLoading] = useState(false)
    const { signup,login }  = useAuth()
    const navigate = useNavigate()

    function resolveAfterRegistering(email,password) {
      return new Promise(async (resolve,reject) => {
        try{
          await signup(email,password);
          await login(email,password);
          resolve('resolved')
        } catch (e) {
          // Fire base error codes
          switch (e.code) {
            case 'auth/email-already-in-use':
              setError('Email already in use')
              break;
            case 'auth/invalid-email':
              setError('Invalid email')
              break;
            case 'auth/weak-password':
              setError('Password must be at least 6 characters')
            break;
            default:
              setError('Could not create user')
            break;
          }
        }
        reject('rejected')
      })
    }



    const handleSubmit = async(e)=> {
        e.preventDefault()
        if (passwordRef.current.value !==passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }
        try {
      
            setError('')
            setLoading(true)
            const registered = await resolveAfterRegistering(emailRef.current.value,passwordRef.current.value)
            registered == 'resolved' && navigate('/profile')

        } catch (e) {
            
        }
        setLoading(false)
        }
        
      
    
      


  return (
    <>
    <div className="h-screen flex flex-col justify-between w-screen p-0 m-0">
    
    <MenuBar />
    <div class="mx-auto text-center my-5">
      <form class="p-5 shadow" onSubmit={handleSubmit}>
        
        <h1 class="text-xl my-4">Create Account</h1>
        
        {error && <p className="alert alert-danger alert-dismissible">{error}</p>}
        
        <Input type="email" className="my-2" id="floatingEmail" label="Email Address" ref={emailRef} required/>

        <Input type="password" className="my-2" id="floatingPassword" label="Password" ref={passwordRef} required/>
       
        <Input type="password" className="my-2" id="floatingPasswordConfirm" label="Confirm password" ref={passwordConfirmRef} required/>
      
            
        <Button className="w-full bg-caresaverblue" type="submit" disabled={loading}>Sign Up</Button>
            
        <div  class="mt-2">
          <p>Already have an account? <Link className="text-caresaverblue underline" to="/login">Login</Link></p>
        </div>
      </form>
    </div>
    <Footer />
    </div>
    </>
  )
}