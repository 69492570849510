import React from 'react'
import { Link } from 'react-router-dom'
import {Button} from '@nextui-org/react'
import { useAuth } from '../context/AuthContext'
import NavBar from '../layouts/Navbar'
import Footer from '../layouts/Footer'
import { Input } from '@nextui-org/react'


export default function Profile() {

    const {currentUser} = useAuth()




  return (
    <div className="h-screen flex flex-col justify-between w-screen p-0 m-0">
      <NavBar />
        <div className="container px-2 mx-auto md:w-96">
            <h1 className="text-center text-xl mb-4">Profile</h1>
            <Input className="my-2" label="Email Address" disabled defaultValue={currentUser.email}/>
            
            <Link to="/reset-password"><Button className="my-2 w-full">Reset Password</Button></Link>
            
            <hr className="my-4"></hr>
            <Link to="/logout"><Button className="w-full my-2">Logout</Button></Link>


        </div>

        <div>
          <h3>Recently Viewed Practices</h3>
          <ul>
            <li>Practice 1</li>
            <li>Practice 2</li>
            <li>Practice 3</li>
          </ul>
        </div>
      <Footer />
    </div>
  )
}
