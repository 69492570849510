import React, {useState,useEffect} from 'react'
import {Button, Navbar,NavbarBrand,NavbarContent, NavbarItem} from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'

export default function NavBar() {

    const {currentUser} = useAuth()

    const [isLoggedIn,setLoginStatus]=useState()

    useEffect(() => {
        if (typeof currentUser === 'undefined') {
            setLoginStatus('false')
        } else if (currentUser === null) {
            setLoginStatus('false')
        } else if (currentUser) {
            setLoginStatus('true')
        }
    },[currentUser]);



  
  return (
    <>
    <Navbar disableAnimation isBordered>
        <NavbarContent className="gap-4 lg:prose-xl text-caresaverblue" justify="center">
            <NavbarBrand>
                <Link to="/" className="font-bold text-inherit">myMedi</Link>
            </NavbarBrand>
        </NavbarContent>

        {(isLoggedIn==='true') ? 
            <Link to="/profile"><i class="fa fa-user-circle-o text-3xl text-caresaverblue"></i> </Link>
            :
            <NavbarContent className="gap-1 text-caresaverblue" justify="end">
                <NavbarItem className="">
                    <Link to="/login">
                        <Button className="">Login</Button>
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link to="/signup" className="">
                        <Button className="bg-caresaverblue text-white">Signup</Button>
                    </Link>
                </NavbarItem>
            </NavbarContent>
            }
       

    </Navbar>
    </>
  )
}

/*
<NavbarContent className="sm:hidden" justify='start'>
            <NavbarMenuToggle />
        </NavbarContent>

        <NavbarContent className="sm:hidden pr-3" justify="center">
            <NavbarBrand>
                <p className="font-bold text-inherit">CareSaver</p>
            </NavbarBrand>
        </NavbarContent>*/

        // hidden sm:flex