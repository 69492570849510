import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Profile from './pages/Profile';
import Login from './pages/Account/Login';
import Signup from './pages/Account/Signup';
import PasswordReset from './pages/Account/PasswordReset';


import reportWebVitals from './reportWebVitals';
import { NextUIProvider } from '@nextui-org/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Search from './pages/Search';
import PracticeList from './pages/Admin/PracticeList';
import ScrollToTop from './utils/ScrollToTop';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './PrivateRoute';
import Logout from './pages/Account/Logout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <NextUIProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/search" element={<Search />} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset-password" element={<PasswordReset />} />

        </Routes>
      </BrowserRouter>
    </NextUIProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
