import React from 'react'
import { Map, Marker, ZoomControl,Overlay } from 'pigeon-maps'
import SearchMapPracticeCard from './SearchMapPracticeCard'
import { stamenToner,stamenTerrain,maptiler } from "pigeon-maps/providers"

export default function SearchMap({practices}) {

    // Set the default center to the middle of all of the practices. This could be the original postcode searched
    function getCenterOfPoints(practices) {
        let lat = 0
        let long = 0
        let num = 0
        practices.forEach((practice) => {
            lat += practice.location[0]
            long += practice.location[1]
            num++
        })
        return [lat/num,long/num]
    }
    // Set the default zoom to fit all of the practices

    // Component to Map out all of the practices.
    // Use the Marker component to mark the location of each practice
    const practiceLocations = (practice) => {
        return <Marker width={50} anchor={practice.location} color={"#16B7B8"} />
    }

    const practiceCards = (practice) => {
        return <Overlay anchor={practice.location} offset={[56,0]}>
                    <SearchMapPracticeCard practice={practice} />
                </Overlay>
    }

  return (
    <>{practices.length>0 ?
    <Map height={400} defaultCenter={getCenterOfPoints(practices)} defaultZoom={7}>
        {practices.map(practiceLocations)}
        {practices.map(practiceCards)}
        <ZoomControl />
    </Map>
    : <p>No practices found</p>}
    </>
  )
}
