import { db } from "../../../firebase"
import { collection, getDocs } from "firebase/firestore"


export default async function getPracticeList() {
    try {
        const practiceList = []

        // get all documents from the practices collection in firebase
        const collectionRef = collection(db,"practices")
        const querySnapshot = await getDocs(collectionRef)

        querySnapshot.forEach((doc) => {
            // add each document to the practiceList array
            const d = doc.data()
            d.id = doc.id
            practiceList.push(d)
        })
        
        return practiceList
    }
    catch(e) {
        throw (e)
    }
}
