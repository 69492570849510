import { getDistanceFromLatLonInKm } from '../../utils/Distances/distancecalculations'
import getPracticeList from './Admin/GetPracticeList';


  

  // Use function inside of hooks to get all the practices

  export async function searchWithPostCode(postCode, distance) {
    try {
        // Get the list of practices from the database
        const practices = await getPracticeList();


        // Get the coordinates of a post code from postcodes.io api
        const response = await fetch(`https://api.postcodes.io/postcodes/${postCode}`);
        const data = await response.json();

        if (data.status !== 200) {
            throw new Error('Post code not found');
        }
        // Get the coordinates of the post code
        const { longitude, latitude } = data.result;

        
        // Filter the practices by distance
        const filteredPractices = practices.filter(practice => {
            const practiceDistance = getDistanceFromLatLonInKm(latitude, longitude, practice.location[0], practice.location[1]);
            return practiceDistance <= distance*0.621371;
        });

        console.log(filteredPractices);
        return filteredPractices; // Return the filtered practices
    } catch (error) {
        console.log(error);
        throw error; // Rethrow the error for better error handling in the calling code
    }
}

/*
// Practices data base is here
const practices = [
  { "name" : "Albyn Hospital",
    "price" : "£65",
    "location" : [57.1448,-2.1113]
  },
  {
    "name": "Greenfield Medical Centre",
    "price": "£75",
    "location": [52.4215,-1.5799]
  },
  {
    "name": "Riverside Health Clinic",
    "price": "£60",
    "location": [51.4972,-0.1446]
  },
  {
    "name": "Beechwood Health Practice",
    "price": "£85",
    "location": [51.3813,-2.3618]
  },
  {
    "name": "Maple Grove Medical Group",
    "price": "£70",
    "location": [53.4793,-2.2455]
  },
  {
    "name": "Willowbrook Family Health",
    "price": "£65",
    "location": [51.7681,-0.2269]
  },
  {
    "name": "Highland Park Clinic",
    "price": "£90",
    "location": [54.5863,-5.9329]
  },
  {
    "name": "Chestnut Avenue Medical",
    "price": "£55",
    "location": [51.6072,-0.0745]
  },
  {
    "name": "Meadowside Health Center",
    "price": "£80",
    "location": [55.9427,-3.1915]
  },
  {
    "name": "Pineview Family Practice",
    "price": "£70",
    "location": [51.4315,-2.5704]
  },
  {
    "name": "Sunset Medical Group",
    "price": "£75",
    "location": [52.6297,-1.1331]
  },
  {
    "name": "Hillside Health Centre",
    "price": "£85",
    "location": [50.8151,-0.3732]
  },
  {
    "name": "Riverbank Health Services",
    "price": "£60",
    "location": [52.0406,-0.7594]
  },
  {
    "name": "Forestview Medical Practice",
    "price": "£90",
    "location": [51.7636,-3.1708]
  },
  {
    "name": "Meadowlark Clinic",
    "price": "£65",
    "location": [55.8642,-4.2684]
  },
  {
    "name": "Brookside Family Health",
    "price": "£75",
    "location": [51.8815,-0.4196]
  },
  {
    "name": "Hollybrook Medical Centre",
    "price": "£80",
    "location": [53.7996,-1.5498]
  },
  {
    "name": "Lakeside Health Group",
    "price": "£70",
    "location": [51.4824,-0.1739]
  },
  {
    "name": "Cedarwood Medical Practice",
    "price": "£55",
    "location": [54.5867,-5.9301]
  },
  {
    "name": "Parkview Family Clinic",
    "price": "£65",
    "location": [51.3759,-2.3624]
  },
  {
    "name": "Hillcrest Health Services",
    "price": "£75",
    "location": [52.4082,-1.5197]
  }
]
*/