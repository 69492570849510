import React from 'react'
import { Card } from '@nextui-org/react'

export default function SearchMapPracticeCard({practice}) {

  // This is a temporary function to find the lowest treatment price of a practice
  // This should be changed later to use the search term to find the lowest price of that thing searched for
  function findLowestPrice() {
    let lowestPrice = 1000000
    
    if (practice?.treatments === undefined) {
      return false
    }

    practice?.treatments.forEach((treatment) => {
      if (treatment.price < lowestPrice) {
        lowestPrice = treatment.price
      }
    })
    return lowestPrice
  }


  return (
    <Card className="w-28 py-2">
        <p className="my-0 py-0 text">{practice.name}</p>
        <p className="py-0 my-0">From {findLowestPrice()}</p>
    </Card>
  )
}
