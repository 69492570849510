import React from 'react'
import NavBar from '../layouts/Navbar'
import { searchWithPostCode } from '../features/Search/SearchPostcode'
import PracticeCard from '../components/search/PracticeCard'
import SearchPreferences from '../layouts/SearchPreferences'
import SearchAdCard from '../components/search/SearchAdCard'
import { Button, Spinner,Input } from '@nextui-org/react'
import SearchMap from '../components/search/SearchMap'
import { getCoordsFromPostcode } from '../utils/Postcodes/GetCoordsFromPostcode'
import Footer from '../layouts/Footer'


export default function Search() {
    // Get URL search paramaters as state
    const [searchParams, setSearchParams] = React.useState()
    // List of results
    const [practiceList, setPracticeList] = React.useState([])
    // Showing 'map' or 'list' view
    const [viewMode,setViewMode] = React.useState("list")
    // Search loading state
    const [searching, setSearching] = React.useState(false)

    const postCodeSelectionRef = React.useRef()
    const distanceSelectionRef = React.useRef()


    // Get URL search paramaters as state when page loaded
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        setSearchParams(params)
       
    }, [])

    // Search for practices when searchParams changes
    React.useEffect(() => {
        if (!searchParams) {
            return
        }
        try{
        setSearching(true)
        searchWithPostCode(searchParams.get('postcode'),searchParams.get('distance'))
        .then((data) => {
            setPracticeList(data)
            setSearching(false)
        }).catch((error) => {
            console.log(error)
            switch (error.code) {
                case "Post code not found":
                    alert("Invalid postcode")
                    break;
                case "Invalid distance":
                    alert("Invalid distance")
                    break;
            }
        })
        
        } catch (error) {
            console.log(error)
            
        }
    }, [searchParams])


    // Handle set new search params
    function handleSetNewSearchParams() {
        const newParams = new URLSearchParams()
        let postcode = postCodeSelectionRef.current.value
        let distance = distanceSelectionRef.current.value

        if (distance === "") {
            distance = 300
        }

        if (postcode === "" && searchParams.get('postcode')) {
            postcode = searchParams.get('postcode')
        }
            

        newParams.set('postcode',postcode)
        newParams.set('distance',distance)
        setSearchParams(newParams)
    }
        

  return (
    <>
    <div className="h-screen flex flex-col justify-between w-screen p-0 m-0">
    
    <NavBar />
    <div className="prose mx-auto lg:prose-md text-center pt-2 flex-col flex gradient-background">
        
        


        <div>
            <Button onClick={()=>setViewMode('list')}className="mx-1">List</Button>
            <Button onClick={()=>setViewMode('map')}className="mx-1">Map</Button>
        </div>

        {/*Search preferences here*/}
      
        <div className="container mx-auto mb-2 prose text-center flex-col flex">
            <h2 className="mb-2">Initial Consultations </h2>
            
            {viewMode==='list' && 
            <span className="mt-0 pt-0 flex-row flex mx-auto">Within 
                <Input onChange={handleSetNewSearchParams} ref={distanceSelectionRef} placeholder={searchParams && searchParams.get('distance')} className="w-16 text-center mx-1"/>
                miles of 
                <Input onChange={handleSetNewSearchParams} ref={postCodeSelectionRef} placeholder={searchParams && searchParams.get('postcode')} className="w-28 text-center ml-1" />
            </span>}
        
        </div>

        <div>
            {/*Ad mixer here*/}
            
        </div>


        {(!searching && practiceList.length===0 && viewMode==='list') && <p>No practices found.</p>}

        {practiceList && viewMode==='list' && practiceList.map((practice) => {
            return <PracticeCard name={practice.name} price={practice.price} location={practice.location} address={practice.address} postcode={practice.postcode} treatments={practice.treatments}/>
        })}
        {searching && <Spinner size="lg" color="caresaverblue"/>}
        {viewMode==='map' && <SearchMap practices={practiceList} center={searchParams && getCoordsFromPostcode(searchParams.get('postcode'))}/>}
        {/*(practiceList.length === 0 && searchParams) && <p>No practices found</p>*/}
    </div>
    <Footer />
    </div>
    </>
  )
}

//  <SearchPreferences postcode={searchParams && searchParams.get('postcode')} distance={searchParams && searchParams.get('distance')} />
