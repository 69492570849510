// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDejprVhHfQa-6QdfofRD_YpJJhp0axSa8",
  authDomain: "caresaver-c0aac.firebaseapp.com",
  projectId: "caresaver-c0aac",
  storageBucket: "caresaver-c0aac.appspot.com",
  messagingSenderId: "991690521281",
  appId: "1:991690521281:web:da1bbfc248228b14d43240",
  measurementId: "G-51N57L4HZ3"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);