import React, { useState,useEffect} from 'react'
import Footer from '../../layouts/Footer'
import MenuBar from '../../layouts/Navbar'
import { useAuth } from '../../context/AuthContext'
import { useNavigate,Link } from 'react-router-dom'
import { Button } from '@nextui-org/react'

export default function Logout() {
  // Send a page view event to Firebase Analytics
  const [error,setError] = useState('')
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()

  async function handleSubmit() {
    setError('')
    try {
      await logout()
      navigate("/logout#")
      navigate('/login')
    } catch {
      setError('Failed to logout')
    }

  }

  return (
    <>
    <div className="h-screen flex flex-col justify-between w-screen p-0 m-0">
    
      <MenuBar />
      <div class="m-auto border p-5">

            {error && <p className="alert alert-danger alert-dismissible">{error}</p>}

            <p className="text-l">Are you sure you want to logout?</p>
            <Button className="mt-4 w-full" onClick={handleSubmit}>Logout</Button>
            
      </div>
      <Footer />
      </div>
    </>
  )
}